import React from 'react';
import ReactPlayer from 'react-player'
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import Particles from "react-tsparticles";
import { HiArrowNarrowDown } from "react-icons/hi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSpring, animated } from 'react-spring';
import Arrow from './Arrow'
import Nyugo from '../image/Nyugo_basic-file.png'
import VideoPresentation from "../video/storyNyugo.mp4"


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.particlesInit = this.particlesInit.bind(this);
    this.particlesLoaded = this.particlesLoaded.bind(this);
  }

  particlesInit(main) {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }

  particlesLoaded(container) {
    console.log(container);
  }

  render() {
    return (

      <div style={{position: 'relative'}}>
      <Container fluid>
      <div style={{position: 'absolute', zIndex: 1, backgroundColor: '#f2f2f2'}}>

      <Particles
        id="tsparticles"
        init={this.particlesInit}
        loaded={this.particlesLoaded}
        height= {"100vh"}

        options={{
          background: {
            color: {
              value: "#f2f2f2",
            },
          },

          fpsLimit: 60,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 800,
                duration: 8,
                opacity: 0.8,
                size: 80,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 1,
              },
            },
          },
          particles: {
            color: {
              value: "#ff0000",
            },
            links: {
              color: "#000000",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 1200,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 6,
            },
          },
          detectRetina: true,
        }}
      />
      </div>

      <div style={{position: 'relative', zIndex: 2}}>
      <div style={{position: 'absolute', left: 10, top: 10, width: '5rem'}}>
        <Image src={Nyugo} style={{width: '100%', objectFit: 'contain'}}/>

      </div>
      <div style={{position: 'absolute', right: 10, top: 15}}>
        <a href="mailto:hello@nyugo.be">
        <Button variant="dark" style={{fontWeight: 'bold'}}>GET STARTED</Button>
        </a>
      </div>
      <Container fluid>
        <div className="position_h1" style={{textAlign: 'center'}}>
          <h1 className="nyugo_under_title" style={{textAlign: 'center'}}>Mobile & Web development</h1>
        </div>
        <div className="videoApp">
          <ReactPlayer
          url={VideoPresentation}
          width='100%'
          height='100%'
          className="centerVideo"
          style={{borderRadius: 15}}
          controls = {true}
             />
        </div>
      </Container>

      <div style={{position: 'absolute', top: '85vh', width: '100%'}}>
        <h1 style={{textAlign: 'center'}}><Arrow/></h1>
      </div>

      </div>
    </Container>


      </div>

    );
  }
}

export default Header;

/*
<Container style={{justifyContent:'center'}}>
<Row>
  <Col>
  <h1 className="nyugo_title" style={{textAlign:'center', marginTop: 30}}>Nyugo</h1>
  <h3 className="nyugo_under_title" style={{textAlign: 'center', marginTop: 25}}>Création d'application mobile & web </h3>
  <p className="under_construction" style={{textAlign: 'center', marginTop: 90}}>Site en cours de construction</p>
  </Col>
</Row>
<Row>
  <Col>
    <p className="contact" style={{marginTop: 90}}>Nous contacter</p>
  </Col>
</Row>
<Row style={{marginTop: 20}}>
  <Col >
  <p className="contact_info">E-mail: cedric@nyugo.be </p>
  <p className="contact_info">Tel: +32 470/09.77.92 </p>
  </Col>
  <Col>
  <p className="contact_info">E-mail: lorenzo@nyugo.be </p>
  <p className="contact_info">Tel: +32 479/04.93.86 </p>

  </Col>
</Row>

</Container>
*/
