import React from "react";
import { Button, Row, Container, Col, Image, Form } from "react-bootstrap";
import FacebookH from "../image/facebook-h.png";
import InstagramH from "../image/instagram-h.png";
import LinkedinH from "../image/linkedin-h.png";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import Condition from "../image/ConditionsGenerales.pdf";

function Footer() {
  return (
    <Container style={{ backgroundColor: "transparent", padding: 50 }} fluid>
      <Row>
        <Col xl={6} lg={4} md={12} sm={12} style={{}}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 style={{ color: "white" }}>Nyugo</h4>
              <p
                style={{
                  color: "white",
                  padding: 0,
                  margin: 0,
                  marginLeft: 10,
                }}
              >
                Mobile & Web development
              </p>
            </div>
          </div>
          <div style={{ paddingTop: 40 }}>
            <p
              style={{
                color: "white",
                padding: 0,
                margin: 0,
                width: "100%",
                textAlign: "justify",
              }}
            >
              Our mobile and web development company aims at helping SEMs,
              Belgian companies and public authorities to achieve their digital
              transition.
            </p>
          </div>
        </Col>

        <Col
          xl={3}
          lg={4}
          md={12}
          sm={12}
          style={{ textAlign: "center", paddingTop: 40 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaFacebookF size={25} color={"white"} />
            <a
              href="https://www.facebook.com/Nyugo-101109202355134"
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                margin: 0,
                padding: 0,
                marginLeft: 5,
              }}
            >
              Facebook
            </a>
          </div>
        </Col>
        <Col
          xl={3}
          lg={4}
          md={12}
          sm={12}
          style={{ textAlign: "center", paddingTop: 40 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaLinkedinIn size={25} color={"white"} />
            <a
              href="https://www.linkedin.com/company/nyugo"
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                margin: 0,
                padding: 0,
                marginLeft: 6,
                marginTop: 1,
              }}
            >
              Linkedin
            </a>
          </div>
        </Col>
      </Row>
      <Container
        style={{ backgroundColor: "white", height: "1px", marginTop: 20 }}
        fluid
      />
      <Row>
        <Col>
          <p style={{ color: "white", paddingTop: 20, margin: 0 }}>
            © 2023 | All rights reserved | Nyugo​
          </p>
        </Col>
        <Col>
          <div style={{ paddingTop: 20 }}>
            <a
              href={Condition}
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                borderBottom: "none",
                transition: "border-bottom 0.3s",
              }}
              onMouseEnter={(e) =>
                (e.target.style.borderBottom = "1px solid white")
              }
              onMouseLeave={(e) => (e.target.style.borderBottom = "none")}
            >
              General Conditions
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
