import React from 'react';
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import { RiMapPinFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { ImPhone } from "react-icons/im";
import John from '../image/John.jpeg'
import Map from './Map'
import { Fade, Slide} from "react-awesome-reveal";
import { useMediaQuery } from 'react-responsive';



function Contact(){

  const isDesktopOrLaptop = useMediaQuery({
  query: '(min-width: 1224px)'
})
const isMobile = useMediaQuery({
  query: '(max-width: 1223px)'
})

    return (
      <div style={{ backgroundColor: '#f2f2f2', paddingBottom: 100}}>
        <Fade>
      <Container>
        <h1 style={{textAlign: 'center', fontWeight: 'bold'}}>Contact us</h1>
        <Row className="shadow"  style={{marginTop: 70, display: 'flex', justifyContent: 'center', padding: 50}}>
          <Col  xl={6} lg={4} md={12} sm={12}>
          <div style={{padding: 20}}>

            <h4>Where to find us ?</h4>
            <div style={{display: 'flex', paddingTop: 10}}>
              <RiMapPinFill color={"#C40707"} size={20} style={{ marginTop: 1}}/>
              <p style={{marginLeft: 10}}>Office </p>
            </div>
            <p>Rue de Genville(J-J) 92<br/>1350 Orp-Jauche</p>
            <h5>Contact</h5>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 15}}>
              <MdEmail size={20}/>
              <p style={{fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 10}}>Mail</p>
            </div>
            <a href="mailto:hello@nyugo.be">
            <p style={{paddingTop: 10}}>hello@nyugo.be</p>
            </a>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 15}}>
              <ImPhone size={20}/>
              <p style={{fontWeight: 'bold', margin: 0, padding: 0, marginLeft: 10}}>Phone</p>
            </div>
            <p style={{paddingTop: 10}}>+32 479/04.93.86</p>
            <p style={{margin: 0, padding: 0}}>+32 470/09.77.92</p>


          </div>

          </Col>

          {isDesktopOrLaptop &&
              <Col  xl={6} lg={4} md={12} sm={12}>
                <Map/>
              </Col>
            }
          {isMobile &&
            <Col  xl={6} lg={4} md={12} sm={12} style={{width: '100%', height: "calc((25rem/2) * 1.34)"}}>
              <Map/>
            </Col>
          }


        </Row>
      </Container>
      </Fade>
      </div>
    );
  }


export default Contact;
