import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
};

export class MapContainer extends Component {
  state = {
  showingInfoWindow: false,  // Hides or shows the InfoWindow
  activeMarker: {},          // Shows the active marker upon click
  selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
};

onMarkerClick = (props, marker, e) =>
  this.setState({
    selectedPlace: props,
    activeMarker: marker,
    showingInfoWindow: true
  });

onClose = props => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    });
  }
};

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={15}
        style={mapStyles}

        initialCenter={
          {
            lat: 50.67922588304601,
            lng: 4.980202600766562,
          }
        }
      >
      <Marker
          onClick={this.onMarkerClick}
          name={'Rue de Genville(J-J) 92, 1350, Orp-Jauche'}
        />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
    </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDUindBC63g_R-FLM4rYLn8-SrDcCZCprE'
})(MapContainer);
