import React from 'react';
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import Particles from "react-tsparticles";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header'
import Body from './components/Body'
import Portfolio from './components/Portfolio'
import Method from './components/Method'
import Contact from './components/Contact'
import Footer from './components/Footer'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useMediaQuery } from 'react-responsive';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdfRP3GhSMdpFlIkXs384EjD88J0AoTB4",
  authDomain: "nyugo-52520.firebaseapp.com",
  projectId: "nyugo-52520",
  storageBucket: "nyugo-52520.appspot.com",
  messagingSenderId: "261558306332",
  appId: "1:261558306332:web:0c807e92516fdbc21d41d9",
  measurementId: "G-DVT2WEDP2Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

/*
const getVideoSrc = width => {
  if (width >= 1080) return desktopVideo;
  if (width >= 720) return tabletVideo;
  return mobileVideo;
};
*/
function App(){

  const isDesktopOrLaptop = useMediaQuery({
  query: '(min-width: 1224px)'
})
const isMobile = useMediaQuery({
  query: '(max-width: 1223px)'
})

    return (

      <div className="overflowY" style={{ backgroundColor: '#f2f2f2'}}>
        <Header/>
        <div style={{paddingTop: '100vh'}}>
        <Portfolio/>
        {isDesktopOrLaptop &&
          <div style={{paddingBottom: '95vh'}}>
            <Method/>
          </div>
          }
        {isMobile && <Method/>}
        <Contact/>
        </div>
        <div className="App">
        <Footer/>
        </div>
      </div>
    );
  }


export default App;
