import React, { useState, useEffect } from 'react';
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import "../App.css"
import ReactPlayer from 'react-player'
import { MdWeb } from "react-icons/md";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import John from '../image/John.jpeg';
import B19Logo from '../image/logoB19.png'
import AppStore from '../image/appstore.png'
import PlayStore from '../image/playstore.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade, Slide} from "react-awesome-reveal";
import { FaLink } from "react-icons/fa";


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{justifyContent: 'center'}}>

          <Container style={{}} fluid>
            <Row>
              <Col lg={12}>
                <div className="videoApp2" style={{width: '100%', height: '50vh'}}/>
                  <ReactPlayer
                  url={"https://www.youtube.com/watch?v=gMH6Ret7ns8&ab_channel=Nyugo"}
                  width='100%'
                  height='100%'
                  controls = {true}
                  className="centerVideo2"
                     />
              </Col>
            </Row>

          </Container>


      </Modal.Header>
      <Modal.Body>
        <Container style={{backgroundColor: 'white'}}>

          <Row>
            <Col>
              <h3>Development</h3>
              <p style={{paddingTop: 15}}>- React Native, React, NodeJs, Amazon Web Services.</p>
            </Col>
            <Col>
              <h3>Technologies</h3>
              <p style={{paddingTop: 15}}>- IOS, Android, Web, Smartphone, Tablet.</p>
            </Col>

          </Row>
          <Row>
            <Col>
              <div className="appStore">
                <a href="https://apps.apple.com/app/b19/id1242357187" target="_blank" style={{color: 'black', textDecoration: 'none'}}>
                <Image src={AppStore} style={{objectFit: 'contain'}}/>
                </a>
              </div>
            </Col>

            <Col>
              <div className="playStore">
                <a href="https://play.google.com/store/apps/details?id=com.tapptic.b19countryclub&hl=fr&gl=US" target="_blank" style={{color: 'black', textDecoration: 'none'}}>
                  <Image src={PlayStore} style={{objectFit: 'contain'}}/>
                </a>
            </div>


            </Col>

          </Row>
        </Container>

      </Modal.Body>
      <Modal.Footer style={{justifyContent: 'center'}}>
        <button class="button primary-button" onClick={props.onHide}>CLOSE</button>
      </Modal.Footer>
    </Modal>
  );
}


function Portfolio(){

  const [modalShow, setModalShow] = useState(false);

    return (

      <div  className="test" style={{ backgroundColor: '#f2f2f2', paddingBottom: 80, paddingTop: 50}}>
        <Fade>
        <Container style={{backgroundColor: 'transparent'}}>
          <h1 style={{textAlign: 'center', fontWeight: 'bold', paddingBottom: 50, paddingTop: 50}}>Our services</h1>
          <Row style={{paddingTop: 50}}>
            <Col  xl={6} lg={6} md={12} sm={12}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <HiOutlineDeviceMobile size={40}/>
                <h3 style={{padding: 0, margin: 0, marginLeft: 8}}>Mobile</h3>
              </div>
              <h4 style={{paddingTop: 20}}>Development</h4>
              <p style={{textAlign: 'justify'}}>Simple, user-friendly, and interactive - this is how our mobile apps are characterized. We create the best native iOS and Android applications for the most commonly used mobile devices to give your business a boost in this mobile-first age. Your best mobile experience is guaranteed.</p>
            </Col>
            <Col  xl={6} lg={6} md={12} sm={12}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <MdWeb size={40}/>
                <h3 style={{padding: 0, margin: 0, marginLeft: 10}}>Web</h3>
              </div>
              <h4 style={{paddingTop: 20}}>Development</h4>
              <p style={{textAlign: 'justify'}}>Having a responsive and mobile-friendly website will be a step forward for your business. Let your customers learn more about your products and services through a functional website. Our professionals will ensure the best user experience, considering your business goals.</p>
            </Col>

          </Row>
          <Row style={{paddingTop: 20}}>
            <Col  xl={6} lg={6} md={12} sm={12}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <AiOutlineUsergroupDelete size={40} />
                <h3 style={{padding: 0, margin: 0, marginLeft: 10}}>Ongoing</h3>
              </div>
              <h4 style={{paddingTop: 20}}>Support</h4>
              <p  style={{textAlign: 'justify'}}>Our skilled specialists will provide ongoing support to ensure that the systems are operating correctly, to solve any issue you have and answer your queries in no time. Our team will be at your disposal whenever you need support or have sudden questions and issues.</p>
            </Col>
            <Col>
            </Col>
          </Row>
        </Container>
      </Fade>

      <Fade>
      <Container style={{paddingTop: 60}}>
        <h1 style={{textAlign: 'center', fontWeight: 'bold', paddingBottom: 50}}>They use our services</h1>
        <Row style={{paddingTop: 50}}>
          <Col>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Image src={B19Logo} style={{objectFit: 'contain', width: '3rem', backgroundColor: "#092432", borderRadius: 5}}/>
              <h4 style={{textAlign: 'left', margin: 0, padding: 0, marginLeft: 10}}>John-Alexander Bogaerts</h4>
            </div>
            <p style={{marginTop: 20, textAlign: 'justify', width: "70%", paddingBottom: 20}}>The B19 invites its members to participate in activities where they will have the opportunity to expand their professional and friendly network.</p>
              <button class="button primary-button" onClick={() => setModalShow(true)}>DISCOVER</button>
          </Col>
          <Col>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}} >
              <Image src={John} style={{borderRadius: 360, objectFit: 'contain'}}/>
            </div>
          </Col>
        </Row>

        <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      </Container>
    </Fade>

      </div>
    );
  }


export default Portfolio;

/*
<Container className="shadow" style={{backgroundColor: '#333333', padding: 50, borderRadius: 10}}>

  <Slider {...settings}>

    <div style={{padding: 20}}>
      <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
      <Image src={John} style={{borderRadius: 360, objectFit: 'contain'}}/>
      </div>
      <h4 style={{textAlign: 'center', marginTop: 25, color: 'white'}}>John-Alexander Bogaerts</h4>
      <h5 style={{textAlign: 'center', color: 'red', marginTop: 10, color: 'white', fontWeight: 'bold'}}>B19</h5>
        <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >

      <p style={{marginTop: 10, color: "white", textAlign: 'center', width: "50%"}}>The B19 invites its members to participate in activities where they will have the opportunity to expand their professional and friendly network.</p>
      </div>
    </div>
    <div style={{padding: 20}}>
      <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
      <Image src={John} style={{borderRadius: 360, objectFit: 'contain'}}/>
      </div>
      <h4 style={{textAlign: 'center', marginTop: 25, color: 'white'}}>John-Alexander Bogaerts</h4>
      <h5 style={{textAlign: 'center', color: 'red', marginTop: 10, color: 'white'}}>B19</h5>
        <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >

      <p style={{marginTop: 10, color: "white", textAlign: 'center', width: "50%"}}>The B19 invites its members to participate in activities where they will have the opportunity to expand their professional and friendly network.</p>
      </div>
    </div>

</Slider>

</Container>
*/
