import React, { useState } from 'react'
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import { useDrag } from 'react-use-gesture'
import Briefing from '../image/Briefing.jpg'
import Work from '../image/Work.jpg'
import Design from '../image/Design.jpg'
import Dev from '../image/Dev.jpg'
import John from '../image/John.jpeg';
import Maintenance from '../image/Maintenance.jpg'
import { useMediaQuery } from 'react-responsive'
import Slider from "react-slick";
import { Fade, Slide} from "react-awesome-reveal";

import styles from './styles.module.css'

const cards = [
  Maintenance,
  Dev,
  Design,
  Work,
  Briefing,
]

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({ x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 })
const from = (_i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

function Deck() {
  const [test, setTest] = useState(4)
  const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
  const [props, api] = useSprings(cards.length, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
    const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
    if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
    api.start(i => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring

      const isGone = gone.has(index)
      if(isGone)
        setTest(index - 1)
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
      const scale = down ? 1.1 : 1 // Active cards lift up a bit
      return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
    })
    if (!down && gone.size === cards.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => {setTest(i); return to(i)})
      }, 600)
  })
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <>
        {props.map(({ x, y, rot, scale }, i) => (
          <>
          <animated.div className={styles.deck} key={i} style={{ x, y }}>
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div
              {...bind(i)}
              style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${cards[i]})` }}
            />
          </animated.div>

          </>
        ))}
    </>
  )
}

export default function Method() {

  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  color: "red"
};

  const isDesktopOrLaptop = useMediaQuery({
  query: '(min-width: 1224px)'
})

const isMobile = useMediaQuery({
query: '(max-width: 1223px)'
})
  return (

    <div className={` ${styles.container}`}>
        <Fade>
      <h1 style={{textAlign: 'center', fontWeight: 'bold'}}>Our working method</h1>
      </Fade>
      {isDesktopOrLaptop && <Deck />}
      {isMobile &&
        <Container style={{ borderRadius: 10, paddingBottom: 80, paddingTop: 50}}>
          <Fade>
          <Slider {...settings} style={{margin: 10}}>

            <div>
              <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
              <Image className="shadow" src={Briefing} style={{borderRadius: 15, objectFit: 'contain', width: "15rem", padding: 5}}/>
              </div>
            </div>

          <div>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <Image className="shadow" src={Work} style={{borderRadius: 15, objectFit: 'contain', width: "15rem", padding: 5}}/>
            </div>
          </div>

          <div>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <Image className="shadow" src={Design} style={{borderRadius: 15, objectFit: 'contain', width: "15rem", padding: 5}}/>
            </div>
          </div>

          <div>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <Image className="shadow" src={Dev} style={{borderRadius: 15, objectFit: 'contain', width: "15rem", padding: 5}}/>
            </div>
          </div>

          <div>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <Image className="shadow" src={Maintenance} style={{borderRadius: 15, objectFit: 'contain', width: "15rem", padding: 5}}/>
            </div>
          </div>

        </Slider>
        </Fade>
        </Container>}
    </div>

  )
}
