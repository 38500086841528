import { useSpring, animated } from 'react-spring';
import { HiArrowNarrowDown } from "react-icons/hi";
import { RiArrowDownSLine } from "react-icons/ri";
function LoopObject() {
  const styles = useSpring({
    loop: { reverse: true },
    from: { y: 0 },
    to: { y: 30 },
  })

  /* CHANGE ARROW BY IOS */

  return (
    <animated.div
      style={{
        ...styles,
      }}>
      <RiArrowDownSLine color={"white"} style={{backgroundColor: '#333333', borderRadius: 360}}/>
      </animated.div>
  )
}

export default LoopObject
